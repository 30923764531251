<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import { dataMaster, masterComputed, masterMethods, nameMaster, checkPermission } from '@/state/helpers';
import Swal from 'sweetalert2';
import Footer from '@/components/Footer/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: '条件設定',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        LoadingIcon
    },
    data() {
        return {
            index: 1,
            loading: false,
            objectData: {
                key: this.$route.query.key,
                id: '',
                values: []
            },
            data: [
                {
                    value: '',
                    data: []
                }
            ],
            listData: [],
            dataMaster: dataMaster,
            nameMaster: nameMaster,
            submitted: false,
            checkChild: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            checkNull: false
        };
    },
    validations: {},
    computed: {
        ...masterComputed
    },
    mounted() {
        this.getListMaster();
    },
    methods: {
        checkPermission,
        ...masterMethods,
        getListMaster() {
            this.loading = true;
            this.listMasterRule1().then((data) => {
                if (data.code == 200) {
                    this.loading = false;
                    this.index++;
                    this.listData = data.data;
                }
            });
        },
        addChild(parent) {
            parent.data.push({
                value: '',
                data: []
            });
            this.data = Object.assign({}, this.data);
        },
        addChild1(parent) {
            parent.rule_title.push({
                rule_title: ''
            });
        },
        checkAndAlert(obj) {
            for (let key in obj) {
                if (typeof obj[key] === 'object') {
                    this.checkAndAlert(obj[key]);
                } else {
                    if (obj[key] === '') {
                        this.checkNull = true;
                        return true;
                    }
                }
            }
        },

        register() {
            this.checkNull = false;
            this.checkAndAlert(this.data);
            if (this.checkNull) {
                this.$bvToast.toast(`Success!`, {
                    title: `すべての情報を入力してください。`,
                    variant: 'error',
                    toaster: 'b-toaster-top-center',
                    solid: true
                });
            } else {
                this.checkNull = false;
                if (this.data[0] && this.data[0].value && !this.loading) {
                    let obj = {
                        platform: this.data[0].value,
                        condition: []
                    };
                    this.data[0].data.forEach((item) => {
                        obj.condition.push({
                            rule_title: item.value
                        });
                    });

                    this.loading = true;
                    this.registerMasterRule(obj).then((data) => {
                        if (data.code == 200) {
                            setTimeout(() => {
                                this.data = [{ value: '', data: [] }];
                            }, 1000);
                            this.getListMaster();
                            this.$bvToast.toast(`Success!`, {
                                title: `登録されました。`,
                                variant: 'success',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        }
                        this.loading = false;
                    });
                }
            }
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            this.register();
        },
        edit(item, key) {
            if (!item.disabled) {
                this.listData[key].disabled = true;
                this.listData = [].concat(this.listData);
            } else {
                if (item.platform) {
                    let data = Object.assign(item);
                    this.updateMasterRule(data).then(() => {
                        this.listData[key].disabled = false;
                        this.listData = [].concat(this.listData);
                        this.$bvToast.toast(`Update Master Success`, {
                            title: `更新されました。`,
                            variant: 'success',
                            toaster: 'b-toaster-top-center',
                            solid: true
                        });
                    });
                }
            }
        },
        deleteObject(data) {
            Swal.fire({
                title: '削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteMasterRule2(data).then((res) => {
                        if (res.code == 200) {
                            this.$bvToast.toast(`Deleted!`, {
                                title: `削除されました。`,
                                variant: 'success',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                            this.getListMaster();
                        }
                    });
                }
            });
        },
        deleteChild(parentIndex, childIndex) {
            this.listData[parentIndex].data.splice(childIndex, 1);
        },
        deleteChild1(item, key) {
            Swal.fire({
                title: '削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    let data = Object.assign(item);
                    data.rule_id = this.listData[key].id;
                    this.deleteMasterRule1(data).then((res) => {
                        if (res.code == 200) {
                            this.$bvToast.toast(`Deleted!`, {
                                title: `削除されました。`,
                                variant: 'success',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                            this.getListMaster();
                        }
                    });
                }
            });
        },
        deleteChildAdd(parentIndex, childIndex) {
            this.data[parentIndex].data.splice(childIndex, 1);
        },
        goEdit(child) {
            if (child && child.id) {
                this.$router.push({ path: '/master/condition-rule-edit', query: { id: child.id } });
            } else {
                this.$router.push({ path: '/master/condition-rule-edit' });
            }
        },
        edit1(item, key, key1) {
            if (!item.disabled) {
                this.listData[key].rule_title[key1].disabled = true;
                this.listData = [].concat(this.listData);
            } else {
                if (item.rule_title) {
                    let data = Object.assign(item);
                    data.rule_id = this.listData[key].id;
                    this.updateMasterRule1(data).then(() => {
                        this.listData[key].rule_title[key1].disabled = false;
                        this.listData = [].concat(this.listData);
                        this.$bvToast.toast(`Update Master Success`, {
                            title: `更新されました。`,
                            variant: 'success',
                            toaster: 'b-toaster-top-center',
                            solid: true
                        });
                    });
                }
            }
        },
        create1(item, key) {
            this.checkChild = true;
            if (item.rule_title) {
                let data = Object.assign(item);
                let object = {
                    rule_id: this.listData[key].id,
                    data: [data]
                };
                this.createMasterRule1(object).then(() => {
                    this.$bvToast.toast(`Update Master Success`, {
                        title: `更新されました。`,
                        variant: 'success',
                        toaster: 'b-toaster-top-center',
                        solid: true
                    });
                    this.getListMaster();
                    this.checkChild = false;
                });
            }
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="text-right mb-3">
                    <button class="btn btn-primary" @click="goEdit()">新規登録</button>
                </div>
                <div class="card">
                    <div class="card-body" :key="index">
                        <div v-for="(item, k) in listData" :key="item.id">
                            <div class="d-flex align-items-start mt-3">
                                <div class="w-100">
                                    <div class="d-flex">
                                        <input :disabled="true" class="form-control" v-model="item.rule" />
                                        <button type="button" @click="goEdit(item, k)" class="btn btn-primary mx-2" style="white-space: nowrap">
                                            編集
                                        </button>
                                        <button type="button" @click="deleteChild1(item, k)" class="btn btn-danger" style="white-space: nowrap">
                                            削除
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
