<script>
import moment from 'moment';
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import { campaignMethods, checkPermission, keyMaster, mapDataCampaignsCopy, masterMethods, prizeMethods, employeeMethods } from '@/state/helpers';
import Swal from 'sweetalert2';
import { campaignStatus, listContactInformations } from '@/config/var-common';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import ModalCommon from '@/components/Modal/main.vue';
import { InputCheckBox, InputRadio } from '@/components/Input';
import { showMessage } from '@/utils/messages';
import { convertTagsToHashTags, renderLineBreaks } from '@/utils/format';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: 'キャンペーン詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        PageHeader,
        Footer,
        ModalCommon,
        InputCheckBox,
        InputRadio
    },
    data() {
        return {
            title: 'キャンペーン詳細',
            index: 1,
            target_product_lists: {},
            saleStaffObject: {},
            teamObject: {},
            personInChargeObject: {},
            progressStatus: {},
            listDataMasterCategoryProductTarget: [],
            listDataMasterConditionPurchase1: [],
            purchase_conditions: {},
            listCategories: [],
            listDataMasterConditionApplySNS: [],
            listCategoriesSub: [],
            contact_informations: {},
            listItemPersons: [],
            loading: false,
            configKeyword: {
                error: false,
                arrayTag: []
            },
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'キャンペーン一覧',
                    href: '/campaign/list'
                },
                {
                    text: 'キャンペーン詳細',
                    active: true
                }
            ],
            id: this.$route.query.id,
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                type: {
                    id: ''
                },
                contact_informations: [],
                note: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                keyword: '',
                give_away_information: {
                    inflow_destination: '',
                    inflow_rate: '',
                    attrition_rate: ''
                },
                number_of_applications: {
                    total: '',
                    postcard_mail: '',
                    web: '',
                    palnavi: '',
                    sns: '',
                    twitter: '',
                    instagram: '',
                    line: '',
                    others: '',
                    invalid: ''
                },
                number_of_inquiries: {
                    total: '',
                    tel: '',
                    email: '',
                    number_of_cases: '',
                    irregular: '',
                    irregular_content: ''
                },
                campaign_master_order_scopes: []
            },
            listContactInformations: listContactInformations,
            listHistory: [],
            listDataMasterCampaignType: [],
            listDataMasterCampaignTypeChild: [],
            phonePic: null,
            customerTeamPic: null,

            //
            listDataMasterOrderScope: [],
            listOrderFilterLv1: [],
            listOrderFilterLv2: [],
            listOrderFilterLv3: [],
            listOrderFilterLv4: [],
            listChecked: {
                msOrderLv1: [],
                msOrderLv2: [],
                msOrderLv3: [],
                msOrderLv4: []
            },
            listRadio: {
                msOrderLv2: {},
                msOrderLv4: {}
            },
            configModalCommon: {
                title: '受注範囲',
                model: false,
                scrollable: true,
                hideFooter: false,
                class: 'modal-custom-view-cp'
            },
            isShowReceiptExpirationDate: false,
            isShowConditionBuyAndSell: false,
            isShowPublicTime: false,
            tableScopeOrder: [],
            arrayObjectPizes: [],
            subDataCustomerTeamPic: [null],
            isBookmarkCampaign: false,
            hasLoadingBookmark: false
        };
    },
    async mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('user'));
        if (this.$route.query.id) {
            this.handleGetMasterOrderScope();
            this.getListHistory(this.$route.query.id);
            await this.getListMasterCampaignType();
            await this.getListMasterCategoryProductTarget();
            await this.getListMasterConditionPurchase1();
            await this.getDetail(this.$route.query.id);
        }
    },

    methods: {
        checkPermission,
        ...prizeMethods,
        ...masterMethods,
        ...campaignMethods,
        ...employeeMethods,

        async handleGetMasterOrderScope() {
            try {
                const resultMasterOrder = await this.getMasterOrderScope();
                if (resultMasterOrder.code !== 200) return;
                this.listDataMasterOrderScope = resultMasterOrder.data;
            } catch (error) {
                console.log('error', error);
            }
        },

        async getListHistory(id) {
            let query = [`page=1`, `limit=999`, `sorts[id]=desc`, `filters[record_id]=${id}`, `filters[form_slug]=campaign`];
            try {
                const response = await this.histories(query.join('&'));
                this.listHistory = response.data;
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        },

        async getListMasterCategoryProductTarget() {
            this.loading = true;
            try {
                const data = await this.listMaster(keyMaster['campaign.product_target']);
                this.listDataMasterCategoryProductTarget = data;
            } catch (error) {
                console.log('error', error);
            }
        },

        async getListMasterConditionPurchase1() {
            this.loading = true;
            try {
                const data = await this.listMaster(keyMaster['campaign.purchase_conditions_1']);
                this.listDataMasterConditionPurchase1 = data;
            } catch (error) {
                console.log('error', error);
            }
        },

        async getListMasterCampaignType() {
            this.loading = true;
            try {
                const data = await this.listMaster(keyMaster['campaign.type']);
                this.listDataMasterCampaignType = data;
            } catch (error) {
                console.log('error', error);
            }
        },

        async getDetail(id) {
            this.loading = true;
            try {
                const data = await this.detailCampaign(id);
                if (!data.number_of_applications) {
                    data.number_of_applications = {
                        total: '',
                        postcard_mail: '',
                        web: '',
                        palnavi: '',
                        sns: '',
                        twitter: '',
                        instagram: '',
                        line: '',
                        others: '',
                        invalid: ''
                    };
                }
                if (!data.number_of_inquiries) {
                    data.number_of_inquiries = {
                        total: '',
                        tel: '',
                        email: '',
                        number_of_cases: '',
                        irregular: '',
                        irregular_content: ''
                    };
                }
                if (!data.person_in_charge) {
                    data.person_in_charge = {
                        id: ''
                    };
                }
                if (!data.sns_application_condition_1) {
                    data.sns_application_condition_1 = {
                        id: ''
                    };
                }
                if (!data.sns_application_condition_2) {
                    data.sns_application_condition_2 = {
                        id: ''
                    };
                }
                if (!data.purchase_condition_1) {
                    data.purchase_condition_1 = {
                        id: ''
                    };
                }
                if (!data.purchase_condition_2) {
                    data.purchase_condition_2 = {
                        id: ''
                    };
                }
                if (!data.application_condition_1) {
                    data.application_condition_1 = {
                        id: ''
                    };
                }
                if (!data.application_condition_2) {
                    data.application_condition_2 = {
                        id: ''
                    };
                }
                if (!data.team) {
                    data.team = {
                        id: ''
                    };
                }

                if (!data.sale_staff) {
                    data.sale_staff = {
                        id: ''
                    };
                }

                if (!data.associated_campaign) {
                    data.associated_campaign = null;
                }

                this.isBookmarkCampaign = this.userInfo?.campaign_bookmarks?.some((item) => item.id === data.id) ?? false;

                let arrayDataContactInfor = [];
                if (data.contact_informations) {
                    data.contact_informations.forEach((item) => {
                        this.listContactInformations.forEach((listI) => {
                            if (listI.child && listI.child.length > 0) {
                                let indexC = listI.child.findIndex((c) => {
                                    return c.id == item.type?.id;
                                });
                                if (indexC >= 0) {
                                    if (item.extension) {
                                        this.contact_informations[item.type.id] = item.extension ? item.extension.content : '';
                                        arrayDataContactInfor.push(item.type.id);
                                    }
                                }
                            }
                        });

                        let index = this.listContactInformations.findIndex((x) => {
                            return x.id == item?.type?.id;
                        });

                        if (index >= 0) {
                            if (item.type.value === '電話') {
                                this.contact_informations[item.type.id] = data?.phone_setting
                                    ? data.phone_setting.pre_order_phone_numbers ?? data.phone_setting.reservation
                                    : '';
                            } else {
                                if (item.extension) {
                                    this.contact_informations[item.type.id] = item.extension ? item.extension.content : '';
                                }
                            }

                            arrayDataContactInfor.push(item.type.id);
                        }
                    });
                }
                let arrayDataTargetProduct = [];
                if (data.target_product_lists) {
                    data.target_product_lists.forEach((item) => {
                        let index = this.listDataMasterCategoryProductTarget.findIndex((x) => {
                            return x.id == item.id;
                        });
                        if (index >= 0) {
                            if (item.note) {
                                this.target_product_lists[item.id] = item.note ? item.note.detail : '';
                            }
                            arrayDataTargetProduct.push(item.id);
                        }
                    });
                }
                let platforms = [];
                if (data.platforms) {
                    data.platforms.forEach((item) => {
                        platforms.push(item.id);
                    });
                }
                let tool_useds = [];
                if (data.tool_useds) {
                    data.tool_useds.forEach((item) => {
                        tool_useds.push(item.id);
                    });
                }
                let types = [];
                if (data.types) {
                    data.types.forEach((item) => {
                        types.push(item.id);
                    });
                }
                let templates = [];
                if (data.templates) {
                    data.templates.forEach((item) => {
                        templates.push(item.id);
                    });
                }
                if (data.campaign_type) {
                    data.campaign_type_name = data.campaign_type.value;
                    data.campaign_type = data.campaign_type.id;
                }
                let arrayDataPurchase = [];
                if (data.purchase_conditions) {
                    data.purchase_conditions.forEach((item) => {
                        let index = this.listDataMasterConditionPurchase1.findIndex((x) => {
                            return x.id == item.id;
                        });
                        if (index >= 0) {
                            if (item.note) {
                                this.purchase_conditions[item.id] = item.note ? item.note.detail : '';
                            }
                            arrayDataPurchase.push(item.id);
                        }
                    });
                }
                let idex = campaignStatus.findIndex((x) => x.id == data.progress_status);
                if (idex >= 0) {
                    this.progressStatus = campaignStatus[idex];
                }
                this.teamObject = data.team;
                this.saleStaffObject = data.sale_staff;
                this.personInChargeObject = data.person_in_charge;
                this.form = data;
                this.form.target_product_lists = arrayDataTargetProduct;
                this.form.contact_informations = arrayDataContactInfor;
                this.form.purchase_conditions = arrayDataPurchase ?? [];
                this.form.platforms = platforms;
                this.configKeyword.arrayTag = data.keywords;
                this.form.tool_useds = tool_useds;
                this.form.types = types;
                this.form.templates = templates;
                if (data.responsible_sub_persons.length) {
                    if (data.responsible_sub_persons.length === 1) {
                        if (data.responsible_sub_persons[0].sub_person_position === 1) {
                            this.listItemPersons = [
                                ...data.responsible_sub_persons,
                                {
                                    id: '',
                                    name: ''
                                }
                            ];
                        } else {
                            this.listItemPersons = [
                                {
                                    id: '',
                                    name: ''
                                },
                                ...data.responsible_sub_persons
                            ];
                        }
                    } else if (data.responsible_sub_persons.length === 2) {
                        this.listItemPersons = data.responsible_sub_persons;
                    }
                }

                if (this.form.campaign_type) {
                    this.listDataMasterCampaignTypeChild = this.listDataMasterCampaignType
                        .map((itemC) => {
                            if (itemC.id == this.form.campaign_type) {
                                return itemC.data;
                            }
                        })
                        .filter((arr) => arr !== undefined)[0];

                    this.isShowReceiptExpirationDate = this.form.campaign_type === 81;
                }

                this.form.categories.forEach((item) => {
                    this.listCategories.push(item.category_id);
                    let index = this.listDataMasterCampaignTypeChild.findIndex((x) => {
                        return x.id == item.category_id;
                    });
                    let check = this.listDataMasterCampaignTypeChild.findIndex((x) => {
                        return (
                            x.id == item.category_id && (x.value == 'Twitter' || x.value == 'Instagram' || x.value == 'LINE' || x.value == 'Tiktok')
                        );
                    });
                    if (index >= 0) {
                        let obj = {
                            category_id: item.category_id,
                            application_condition_1: item.application_condition_1,
                            application_condition_2_id: item.application_condition_2_id,
                            listCondition1: this.listDataMasterCampaignTypeChild[index].data,
                            listCondition2: [],
                            hashtag: item.hashtag ?? [],
                            configHashTag: {
                                arrayTag: item.hashtag ?? []
                            },
                            extension: item.extension
                                ? item.extension
                                : {
                                      offical_name_sns: '',
                                      offical_id_sns: '',
                                      number_of_circulation: '',
                                      company_information: '',
                                      other_type_data: '',
                                      application_method: ''
                                  },
                            checkSNS: check
                        };

                        let i = obj.listCondition1.findIndex((x) => {
                            return obj.application_condition_1 && x.id == obj.application_condition_1.id;
                        });
                        if (i >= 0) {
                            obj.listCondition2 = obj.listCondition1[i].data;
                        }
                        this.listCategoriesSub.push(obj);
                        this.listCategoriesSub = this.listCategoriesSub.sort((a, b) => a.category_id - b.category_id);
                    }
                });

                this.arrayObjectPizes =
                    data.event_prizes.length > 0
                        ? data.event_prizes.map((eventPrize) => {
                              return {
                                  id: eventPrize.id,
                                  title: eventPrize.title,
                                  errorTitle: '',
                                  number_of_win_prize: eventPrize.number_of_win_prize,
                                  number_of_people_drawing_lots: eventPrize.number_of_people_drawing_lots,
                                  arrayNameAndQuantity:
                                      eventPrize.prizes.length > 0
                                          ? eventPrize.prizes.map((pz) => {
                                                return {
                                                    id: pz.id,
                                                    prize_name: pz.name ?? '',
                                                    configPrizeName: {
                                                        isRequired: false,
                                                        isMaxLength: false,
                                                        error: false,
                                                        errorField: '賞品名'
                                                    },
                                                    quantity: pz.quantity ?? 0,
                                                    quantity_title: pz.quantity * eventPrize.number_of_win_prize ?? 0,
                                                    quantity_received: pz.quantity_received ?? 0,
                                                    quantity_remaining: pz.quantity_received - pz.quantity * eventPrize.number_of_win_prize ?? 0,
                                                    configRemaining: {
                                                        isNumber: true,
                                                        maxlength: 10,
                                                        error: false,
                                                        errorCustomMess: ''
                                                    }
                                                };
                                            })
                                          : [
                                                {
                                                    prize_name: '',
                                                    configPrizeName: {
                                                        isRequired: false,
                                                        isMaxLength: false,
                                                        error: false,
                                                        errorField: '賞品名'
                                                    },
                                                    quantity: 0,
                                                    quantity_title: 0,
                                                    quantity_received: 0,
                                                    quantity_remaining: 0,
                                                    configRemaining: {
                                                        isNumber: true,
                                                        maxlength: 10,
                                                        error: false,
                                                        errorCustomMess: ''
                                                    }
                                                }
                                            ]
                              };
                          })
                        : [];

                this.purchase_condition_2 = this.form.purchase_condition_2;
                this.phonePic = data.phone_pic ?? '';
                this.customerTeamPic = data.customer_team_pic ?? '';
                this.form.source_data = {
                    type: data.source_data.type,
                    id: this.form.source_data.id,
                    name: data.clients.find((client) => client.id === this.form.source_data.id)?.name ?? ''
                };
                this.subDataCustomerTeamPic = data.customer_sub_person.length > 0 ? data.customer_sub_person : [null];
                this.form.information_ips =
                    data.information_ips?.map((item) => {
                        return {
                            master_id: item.id,
                            value: item.value,
                            note: {
                                detail: item?.note?.detail ?? ''
                            }
                        };
                    }) ?? [];
                this.handleMapDataRegisted(data.campaign_master_order_scopes);
                this.handleShowConditionBuyAndSell();
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.log('error', error);
            }
        },

        renderCategory(categoryId) {
            let index = this.listDataMasterCampaignTypeChild.findIndex((x) => {
                return x.id == categoryId;
            });
            if (index >= 0) {
                return this.listDataMasterCampaignTypeChild[index].value;
            }
            return '';
        },

        preventInputE(event) {
            // Ngăn chặn ký tự "e" từ được nhập vào ô input
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
        },
        goToEdit(path, id, partner_id) {
            this.$router.push({ path: path, query: { id: id, partner_id: partner_id } });
        },
        formatDateToChinese(time) {
            // const months = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];if
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = moment(time).format('HH:mm') ?? '';

                if (time.includes(' ')) {
                    return `${year} 年 ${month} 月 ${day} 日 ${hours}`;
                } else {
                    return `${year} 年 ${month} 月 ${day} 日`;
                }
            }
            return '';
        },
        deleteObject(data) {
            Swal.fire({
                title: 'このキャンペーンを削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                cancelButtonText: 'キャンセル',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.value) {
                    this.deleteCampaign(data).then((data) => {
                        if (data.code == 200) {
                            this.$router.push({ path: '/campaign/list' }).then(() => {
                                this.$bvToast.toast(`Delete`, {
                                    title: `キャンペーンが削除されました。`,
                                    variant: 'success',
                                    toaster: 'b-toaster-top-center',
                                    solid: true
                                });
                            });
                        }
                    });
                }
            });
        },
        renderStatus(status) {
            let index = campaignStatus.findIndex((x) => {
                return x.id == status;
            });
            if (index >= 0) {
                return campaignStatus[index].text;
            }
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        lockCampaigns() {
            this.lockCampaign(this.$route.query.id).then((data) => {
                if (data.code == 200) {
                    this.$bvToast.toast(`Your record has been deleted.`, {
                        title: `このキャンペーンはロックされました。`,
                        variant: 'success',
                        toaster: 'b-toaster-top-center',
                        solid: true
                    });
                    this.getDetail(this.$route.query.id);
                }
            });
        },
        unLockCampaigns() {
            this.unLockCampaign(this.$route.query.id).then((data) => {
                if (data.code == 200) {
                    this.$bvToast.toast(`Your record has been deleted.`, {
                        title: `ロックが解除されました。`,
                        variant: 'success',
                        toaster: 'b-toaster-top-center',
                        solid: true
                    });
                    this.getDetail(this.$route.query.id);
                }
            });
        },
        approveCampaigns() {
            this.approveCampaign(this.$route.query.id).then((data) => {
                if (data.code == 200) {
                    this.getDetail(this.$route.query.id);
                }
            });
        },

        handleMapDataPrizes(arrayObjectPizes) {
            let mapData = arrayObjectPizes.map((item) => {
                return {
                    title: item.title,
                    number_of_win_prize: Number(item.number_of_win_prize) ?? 0,
                    number_of_people_drawing_lots: Number(item.number_of_people_drawing_lots) ?? 0,
                    prizes: item.prizes.map((pz) => {
                        return {
                            name: pz.name,
                            quantity: Number(pz.quantity),
                            quantity_received: Number(pz.quantity_received)
                        };
                    })
                };
            });
            return mapData ?? [];
        },

        getCurrentDateTime(date) {
            var now = new Date(date);
            var year = now.getFullYear();
            var month = ('0' + (now.getMonth() + 1)).slice(-2);
            var day = ('0' + now.getDate()).slice(-2);

            var hours = ('0' + now.getHours()).slice(-2);
            var minutes = ('0' + now.getMinutes()).slice(-2);
            var seconds = ('0' + now.getSeconds()).slice(-2);
            var formattedDateTime = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;

            return formattedDateTime;
        },

        renderNameTargetProduct(i) {
            let index = this.listDataMasterCategoryProductTarget.findIndex((x) => {
                return x.id == i;
            });
            if (index >= 0) {
                return this.listDataMasterCategoryProductTarget[index].value;
            }
            return '';
        },

        renderNameCategory(item) {
            let index = this.listDataMasterCampaignTypeChild.findIndex((x) => {
                return x.id == item.category_id;
            });
            if (index >= 0) {
                return this.listDataMasterCampaignTypeChild[index].value;
            }
        },
        renderName(object, data) {
            let index = data.findIndex((x) => {
                return object && x.id == object.id;
            });
            if (index >= 0) {
                return data[index].value;
            }
            return '';
        },
        handleRenderNameCondition2(arrayCondition2, applicationCondition2Id) {
            let arrayMapId = [];
            if (arrayCondition2.length > 0 && applicationCondition2Id) {
                arrayMapId = arrayCondition2.map((item) => item.id);
                let listConditionTM = applicationCondition2Id.filter((x) => arrayMapId.find((y) => y == x));
                let textArr = arrayCondition2
                    .map((item) => {
                        let index = listConditionTM.findIndex((x) => x == item.id);
                        if (index >= 0) {
                            return item.value;
                        }
                    })
                    .filter((arr) => arr !== undefined);
                return textArr.join('、');
            }
            return '';
        },

        copyCampaigns() {
            if (this.form.name) {
                let object = JSON.parse(JSON.stringify(this.form));
                object.name = object.name + ' Copy ' + this.getCurrentDateTime(new Date());
                let content_projects = [];
                if (object.content_projects) {
                    object.content_projects.forEach((item) => {
                        content_projects.push(item.id);
                    });
                }
                object.content_projects = content_projects;
                if (object.prizes && object.prizes.length > 0) {
                    object.prizes.forEach((item, k) => {
                        let digital_gifts = {};
                        if (item.digital_gifts && item.digital_gifts[0] && item.digital_gifts[0].id) {
                            let not = JSON.parse(item.digital_gifts[0].extension);
                            digital_gifts[item.digital_gifts[0].id] = {
                                extension: {
                                    value_note: not.value_note
                                }
                            };
                        }
                        object.prizes[k].digital_gifts = digital_gifts;
                    });
                }
                object.event_prizes = this.handleMapDataPrizes(object.event_prizes);

                let objectData = mapDataCampaignsCopy(
                    object,
                    this.configKeyword,
                    this.listItemPersons,
                    object.prizes,
                    this.target_product_lists,
                    this.purchase_conditions,
                    this.contact_informations,
                    this.listCategoriesSub,
                    this.subDataCustomerTeamPic
                );
                if (this.loadingExcute) return;
                this.loadingExcute = true;
                this.copyCampaign(objectData).then((data) => {
                    this.loadingExcute = false;
                    if (data.code == 200) {
                        this.$router.push({ path: '/campaign/list' });
                    } else {
                        showMessage(data.code, this.$bvToast, this.$t(data.message));
                    }
                });
            }
        },

        textLineBreaks(text) {
            return renderLineBreaks(text);
        },

        handleRenderChatRoom(roomChat) {
            if (!roomChat || roomChat.length <= 0) return '';
            return roomChat
                .map((item) => {
                    return `${item.room_name} - ${item.room_id}`;
                })
                .join('、');
        },

        handleShowOptionOneFilterLv2(itemFilterLv2) {
            return (
                itemFilterLv2.value == '電話' ||
                itemFilterLv2.value == 'メール' ||
                itemFilterLv2.value == 'はがき回収' ||
                itemFilterLv2.value == 'WEBフォーム' ||
                itemFilterLv2.value == '応募数報告' ||
                itemFilterLv2.value == 'お問い合わせ報告' ||
                itemFilterLv2.value == '現物' ||
                itemFilterLv2.value == '当選者用フォーム'
            );
        },

        handleShowOptionOneFilterLv3(itemFilterLv3) {
            return itemFilterLv3.value == '応募数報告' || itemFilterLv3.value == 'お問い合わせ報告' || itemFilterLv3.value == '現物';
        },

        handleVisibleLv2(itemLv1, itemLv2) {
            const result = itemLv1.data.map((item) => item.id).includes(itemLv2.id);
            return result;
        },

        handleVisibleLv3(itemLv1, itemLv2, itemLv3) {
            const resultItemLv2 = itemLv2.data.find((item) => item.id === itemLv3.id);
            if (resultItemLv2) {
                return resultItemLv2.origin_parent_id === itemLv1.id;
            }
        },

        handleShowPublicTime() {
            // 95 LPサイト
            if (this.listChecked.msOrderLv3.includes(95)) {
                this.isShowPublicTime = true;
            } else {
                this.isShowPublicTime = false;
            }
        },

        handleChangeOrderLv1(itemLv1) {
            if (this.listChecked.msOrderLv1.includes(itemLv1.id)) {
                this.listOrderFilterLv1.push(itemLv1);
            } else {
                this.listOrderFilterLv1 = this.listOrderFilterLv1.filter((item) => item.id !== itemLv1.id);
                itemLv1.data.forEach((lv1) => {
                    this.listChecked.msOrderLv2 = this.listChecked.msOrderLv2.filter((itemLv2) => lv1.id !== itemLv2);
                    this.listOrderFilterLv2 = this.listOrderFilterLv2.filter((orderFl2) => orderFl2.id !== lv1.id);
                    if (this.handleShowOptionOneFilterLv2(lv1)) {
                        this.listRadio.msOrderLv2[lv1.id] = '';
                    }
                    lv1.data.forEach((lv2) => {
                        if (this.handleShowOptionOneFilterLv3(lv2)) {
                            this.listRadio.msOrderLv4[lv2.id] = '';
                        }
                        this.listChecked.msOrderLv3 = this.listChecked.msOrderLv3.filter((itemLv3) => lv2.id !== itemLv3);
                        this.listOrderFilterLv3 = this.listOrderFilterLv3.filter((orderFl3) => orderFl3.id !== lv2.id);
                        lv2.data.forEach((lv3) => {
                            if (this.handleShowOptionOneFilterLv3(lv3)) {
                                this.listRadio.msOrderLv4[lv3.id] = '';
                            }
                            this.listChecked.msOrderLv4 = this.listChecked.msOrderLv4.filter((itemLv4) => lv3.id !== itemLv4);
                            this.listOrderFilterLv4 = this.listOrderFilterLv4.filter((orderFl4) => orderFl4.id !== lv3.id);
                            this.contact_informations[lv3.id] = '';
                        });
                    });
                });
            }
            this.handleShowPublicTime();
        },

        handleChangeOrderLv2(itemLv2) {
            if (this.listChecked.msOrderLv2.includes(itemLv2.id)) {
                this.listOrderFilterLv2.push(itemLv2);
            } else {
                this.listOrderFilterLv2 = this.listOrderFilterLv2.filter((item) => item.id !== itemLv2.id);
                if (this.handleShowOptionOneFilterLv2(itemLv2)) {
                    this.listRadio.msOrderLv2[itemLv2.id] = '';
                }
                itemLv2.data.forEach((lv2) => {
                    if (this.handleShowOptionOneFilterLv3(lv2)) {
                        this.listRadio.msOrderLv4[lv2.id] = '';
                    }
                    this.listChecked.msOrderLv3 = this.listChecked.msOrderLv3.filter((itemLv3) => lv2.id !== itemLv3);
                    this.listOrderFilterLv3 = this.listOrderFilterLv3.filter((orderFl3) => orderFl3.id !== lv2.id);
                    lv2.data.forEach((lv3) => {
                        this.listChecked.msOrderLv4 = this.listChecked.msOrderLv4.filter((itemLv4) => lv3.id !== itemLv4);
                        this.listOrderFilterLv4 = this.listOrderFilterLv4.filter((orderFl4) => orderFl4.id !== lv3.id);
                        this.contact_informations[lv3.id] = '';
                    });
                });
            }
            this.handleShowPublicTime();
        },

        handleChangeOrderLv3(itemLv3) {
            if (this.listChecked.msOrderLv3.includes(itemLv3.id)) {
                this.listOrderFilterLv3.push(itemLv3);
            } else {
                this.listOrderFilterLv3 = this.listOrderFilterLv3.filter((item) => item.id !== itemLv3.id);
                if (this.handleShowOptionOneFilterLv3(itemLv3)) {
                    this.listRadio.msOrderLv4[itemLv3.id] = '';
                }
                itemLv3.data.forEach((lv3) => {
                    this.listChecked.msOrderLv4 = this.listChecked.msOrderLv4.filter((itemLv4) => lv3.id !== itemLv4);
                    this.listOrderFilterLv4 = this.listOrderFilterLv4.filter((orderFl4) => orderFl4.id !== lv3.id);
                    this.contact_informations[lv3.id] = '';
                });
            }
            this.handleShowPublicTime();
        },

        handleChangeOrderLv4(itemLv4) {
            if (this.listChecked.msOrderLv4.includes(itemLv4.id)) {
                this.listOrderFilterLv4.push(itemLv4);
            } else {
                this.listOrderFilterLv4 = this.listOrderFilterLv4.filter((item) => item.id !== itemLv4.id);
                this.contact_informations[itemLv4.id] = '';
            }
        },

        handleOpenScopeOrder() {
            this.configModalCommon.model = true;
            this.listOrderFilterLv1 = [];
            this.listOrderFilterLv2 = [];
            this.listOrderFilterLv3 = [];
            this.listOrderFilterLv4 = [];

            // check data be trả về
            this.listChecked.msOrderLv1.forEach((idOrigin) => {
                const obj = this.listDataMasterOrderScope.find((x) => x.id === idOrigin);
                if (obj) {
                    this.handleChangeOrderLv1(obj);
                    obj.data.forEach((lv1) => {
                        this.handleChangeOrderLv2(lv1);
                        lv1.data.forEach((lv2) => {
                            this.handleChangeOrderLv3(lv2);
                        });
                    });
                }
            });
        },

        handleCloseScopeOrder() {
            this.listChecked = {
                msOrderLv1: [],
                msOrderLv2: [],
                msOrderLv3: [],
                msOrderLv4: []
            };
            this.listRadio = {
                msOrderLv2: {},
                msOrderLv4: {}
            };
            this.listOrderFilterLv1 = [];
            this.listOrderFilterLv2 = [];
            this.listOrderFilterLv3 = [];
            this.listOrderFilterLv4 = [];

            this.handleMapDataRegisted();
        },

        handleMapDataRegisted() {
            if (this.form.campaign_master_order_scopes.length > 0) {
                this.form.campaign_master_order_scopes.forEach((itemLv1) => {
                    if (!this.listChecked.msOrderLv1.includes(itemLv1.large_item.id)) {
                        this.listChecked.msOrderLv1.push(itemLv1.large_item.id);
                    }
                    if (itemLv1.medium_items.length > 0) {
                        itemLv1.medium_items.forEach((itemLv2) => {
                            if (!this.listChecked.msOrderLv2.includes(itemLv2.medium_item.id)) {
                                this.listChecked.msOrderLv2.push(itemLv2.medium_item.id);
                            }
                            this.listRadio.msOrderLv2[itemLv1.large_item.id] = itemLv2.medium_item.id;
                            if (itemLv2.small_items.length > 0) {
                                itemLv2.small_items.forEach((itemLv3) => {
                                    if (!this.listChecked.msOrderLv3.includes(itemLv3.small_item.id)) {
                                        this.listChecked.msOrderLv3.push(itemLv3.small_item.id);
                                    }
                                    this.listRadio.msOrderLv2[itemLv2.medium_item.id] = itemLv3.small_item.id;
                                    if (itemLv3.detail_items && itemLv3.detail_items.length > 0) {
                                        itemLv3.detail_items.forEach((itemLv4) => {
                                            if (!this.listChecked.msOrderLv4.includes(itemLv4.id)) {
                                                this.listChecked.msOrderLv4.push(itemLv4.id);
                                            }
                                            this.listRadio.msOrderLv4[itemLv3.small_item.id] = itemLv4.id;
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
            this.handleShowPublicTime();
            this.handleEmitSaveScopeOrder(this.listChecked, this.listRadio);
        },

        handleShowConditionBuyAndSell() {
            if (!this.form.campaign_type || this.form.campaign_type !== 81) return;

            this.dataApplycation2Type1 = this.listCategoriesSub.map((item) => item.listCondition2).flat();
            this.listIDSelectApplycation2Type1 = this.listCategoriesSub
                .map((item) => item.application_condition_2_id)
                .flat()
                .map(Number);

            const listSelected = this.dataApplycation2Type1.filter((itemAPT2) => this.listIDSelectApplycation2Type1.includes(itemAPT2.id));
            if (!listSelected.length) {
                this.isShowConditionBuyAndSell = false;
            } else {
                const listValue = listSelected.map((itemSelected) => itemSelected.value);
                this.isShowConditionBuyAndSell = listValue.includes('WEB明細') || listValue.includes('レシート（購買証明）');
            }
        },

        renderNamePurchase(i) {
            let index = this.listDataMasterConditionPurchase1.findIndex((x) => {
                return x.id == i;
            });
            if (index >= 0) {
                return this.listDataMasterConditionPurchase1[index].value;
            }
            return '';
        },

        handleEmitSaveScopeOrder(scopeChecked, scopeRadio) {
            this.tableScopeOrder = this.listDataMasterOrderScope
                .flatMap((itemOrigin) => {
                    if (scopeChecked.msOrderLv1.includes(itemOrigin.id)) {
                        return {
                            id: itemOrigin.id,
                            value: itemOrigin.value,
                            data: itemOrigin.data
                                .flatMap((itemLv2) => {
                                    if (scopeChecked.msOrderLv2.includes(itemLv2.id)) {
                                        return {
                                            id: itemLv2.id,
                                            value: itemLv2.value,
                                            data: this.handleShowOptionOneFilterLv2(itemLv2)
                                                ? itemLv2.data
                                                      .flatMap((itemLv3) => {
                                                          if (scopeRadio.msOrderLv2[itemLv2.id] === itemLv3.id) {
                                                              return {
                                                                  id: itemLv3.id,
                                                                  value: itemLv3.value,
                                                                  partner_corresponding: null,
                                                                  toolInternal: [],
                                                                  tool_corresponding: null
                                                              };
                                                          }
                                                          return undefined;
                                                      })
                                                      .filter((oLv2) => oLv2 !== undefined)
                                                : itemLv2.data
                                                      .flatMap((itemLv3) => {
                                                          if (scopeChecked.msOrderLv3.includes(itemLv3.id)) {
                                                              return {
                                                                  id: itemLv3.id,
                                                                  value: itemLv3.value,
                                                                  data: this.handleShowOptionOneFilterLv3(itemLv3)
                                                                      ? itemLv3.data
                                                                            .flatMap((itemLv4) => {
                                                                                if (scopeRadio.msOrderLv4[itemLv3.id] === itemLv4.id) {
                                                                                    return {
                                                                                        id: itemLv4.id,
                                                                                        value: itemLv4.value,
                                                                                        partner_corresponding: null,
                                                                                        toolInternal: [],
                                                                                        tool_corresponding: null
                                                                                    };
                                                                                }
                                                                                return undefined;
                                                                            })
                                                                            .filter((oLv4) => oLv4 !== undefined)
                                                                      : itemLv3.data
                                                                            .flatMap((itemLv4) => {
                                                                                if (scopeChecked.msOrderLv4.includes(itemLv4.id)) {
                                                                                    return {
                                                                                        id: itemLv4.id,
                                                                                        value: itemLv4.value,
                                                                                        partner_corresponding: null,
                                                                                        toolInternal: [],
                                                                                        tool_corresponding: null
                                                                                    };
                                                                                }
                                                                                return undefined;
                                                                            })
                                                                            .filter((oLv4) => oLv4 !== undefined),
                                                                  partner_corresponding: null,
                                                                  configPartnerCorresponding: {
                                                                      error: false,
                                                                      isRemove: true,
                                                                      trackBy: 'id',
                                                                      label: 'name',
                                                                      loading: false
                                                                  },
                                                                  toolInternal: [],
                                                                  tool_corresponding: null,
                                                                  configToolCorresponding: {
                                                                      error: false,
                                                                      isRemove: false,
                                                                      trackBy: 'id',
                                                                      label: 'tool',
                                                                      loading: false
                                                                  }
                                                              };
                                                          }
                                                          return undefined;
                                                      })
                                                      .filter((oLv3) => oLv3 !== undefined),
                                            partner_corresponding: null,
                                            configPartnerCorresponding: {
                                                error: false,
                                                isRemove: true,
                                                trackBy: 'id',
                                                label: 'name',
                                                loading: false
                                            },
                                            toolInternal: [],
                                            tool_corresponding: null,
                                            configToolCorresponding: {
                                                error: false,
                                                isRemove: false,
                                                trackBy: 'id',
                                                label: 'tool',
                                                loading: false
                                            }
                                        };
                                    }
                                    return undefined;
                                })
                                .filter((oLv2) => oLv2 !== undefined),
                            partner_corresponding: null,
                            configPartnerCorresponding: {
                                error: false,
                                isRemove: true,
                                trackBy: 'id',
                                label: 'name',
                                loading: false
                            },
                            toolInternal: [],
                            tool_corresponding: null,
                            configToolCorresponding: {
                                error: false,
                                isRemove: false,
                                trackBy: 'id',
                                label: 'tool',
                                loading: false
                            }
                        };
                    }
                    return undefined;
                })
                .filter((o) => o !== undefined);

            if (this.form.campaign_master_order_scope_partner.length > 0) {
                this.tableScopeOrder.forEach((lv1) => {
                    const lv1Obj = this.form.campaign_master_order_scope_partner.find((item) => item.master_order_scope.id === lv1.id);
                    if (lv1Obj) {
                        lv1.idResgited = lv1Obj.id;
                        lv1.partner_corresponding = lv1Obj.partner ?? null;
                        lv1.tool_corresponding = lv1Obj.tool ?? null;
                    }
                    lv1.data.forEach((lv2) => {
                        const lv2Obj = this.form.campaign_master_order_scope_partner.find((item) => item.master_order_scope.id === lv2.id);
                        if (lv2Obj) {
                            lv2.idResgited = lv2Obj.id;
                            lv2.partner_corresponding = lv2Obj.partner ?? null;
                            lv2.tool_corresponding = lv2Obj.tool ?? null;
                        }
                        lv2.data.forEach((lv3) => {
                            const lv3Obj = this.form.campaign_master_order_scope_partner.find((item) => item.master_order_scope.id === lv3.id);
                            if (lv3Obj) {
                                lv3.idResgited = lv3Obj.id;
                                lv3.partner_corresponding = lv3Obj.partner ?? null;
                                lv3.tool_corresponding = lv3Obj.tool ?? null;
                            }
                            lv3.data?.forEach((lv4) => {
                                const lv4Obj = this.form.campaign_master_order_scope_partner.find((item) => item.master_order_scope.id === lv4.id);
                                if (lv4Obj) {
                                    lv4.idResgited = lv4Obj.id;
                                    lv4.partner_corresponding = lv4Obj.partner ?? null;
                                    lv4.tool_corresponding = lv4Obj.tool ?? null;
                                }
                            });
                        });
                    });
                });
            }
        },

        handleRenderNameCommon(param1, param2) {
            const resultText = param1.filter((item) => param2.includes(item.id));
            if (resultText.length <= 0) return '';
            return resultText.map((rs) => rs.value).join('、');
        },
        handleRenderNameEmployePT(params) {
            if (params && params.length > 0) {
                return params
                    .map((item) => {
                        return item.name;
                    })
                    .join('、');
            }
            return '';
        },

        handleRenderHashTag(hashTag) {
            if (hashTag && hashTag.length > 0) {
                return convertTagsToHashTags(hashTag).join('、');
            }
            return '';
        },

        async handleSetBookmarkCampaign() {
            if (this.hasLoadingBookmark) return;
            this.hasLoadingBookmark = true;
            this.isBookmarkCampaign = true;
            try {
                this.userInfo.campaign_bookmarks.push({ id: this.form.id, name: this.form.name });
                localStorage.setItem('user', JSON.stringify(this.userInfo));
                await this.setBookmarkCampaign(this.form.id);
            } catch (error) {
                console.log(error);
            } finally {
                this.hasLoadingBookmark = false;
            }
        },
        async handleUnsetBookmarkCampaign() {
            if (this.hasLoadingBookmark) return;
            this.hasLoadingBookmark = true;
            this.isBookmarkCampaign = false;
            try {
                this.userInfo.campaign_bookmarks = this.userInfo.campaign_bookmarks.filter((item) => item.id !== this.form.id);
                localStorage.setItem('user', JSON.stringify(this.userInfo));
                await this.unsetBookmarkCampaign(this.form.id);
            } catch (error) {
                console.log(error);
            } finally {
                this.hasLoadingBookmark = false;
            }
        },

        handleGetNameIP(namesIP) {
            if (namesIP && namesIP.length > 0) {
                return namesIP.map((item) => item.value).join('、');
            }
            return '';
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card form--sroll1 mb-0">
                    <div class="card-header">
                        <h5>{{ form.name }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form class="needs-validation" autocomplete="off">
                                    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                                        <b-tab active class="border-0">
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="fas fa-home"></i>
                                                </span>
                                                <span class="">基本情報</span>
                                            </template>
                                            <div class="form-group row">
                                                <label class="col-sm-3">進捗ステータス</label>
                                                <div class="col-sm-8">
                                                    <div style="position: relative">
                                                        {{ progressStatus.text }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3">キャンペーン名称</label>
                                                <div class="col-sm-8">
                                                    {{ form.name }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">ZACID</label>
                                                <div class="col-sm-8">
                                                    {{ form.zac_id }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">キャンペーン種別</label>
                                                <div class="col-sm-8">
                                                    {{ form.campaign_type_name }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">受注範囲</label>
                                                <div class="col-sm-8">
                                                    <button type="button" class="btn btn-sm btn-info" @click="handleOpenScopeOrder()">詳細</button>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 pr-0">キャンペーン開始年月日</label>
                                                <div class="col-sm-8">
                                                    {{ formatDateToChinese(form.campaign_start_date) }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">キャンペーン終了年月日</label>
                                                <div class="col-sm-8">
                                                    {{ formatDateToChinese(form.campaign_end_date) }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3">事務局開始年月日</label>
                                                <div class="col-sm-8">
                                                    {{ formatDateToChinese(form.secretariat_start_date) }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">事務局終了年月日</label>
                                                <div class="col-sm-8">
                                                    {{ formatDateToChinese(form.secretariat_end_date) }}
                                                </div>
                                            </div>

                                            <div v-if="isShowReceiptExpirationDate" class="form-group row">
                                                <label class="col-sm-3 pr-0">レシート有効期限</label>
                                                <div class="col-sm-8">
                                                    {{ formatDateToChinese(form.receipt_expiration_date) }}
                                                </div>
                                            </div>

                                            <div class="mb-1 row" v-if="form.campaign_type">
                                                <label class="col-sm-3">応募種別</label>
                                                <div class="col-sm-8">
                                                    {{ handleRenderNameCommon(listDataMasterCampaignTypeChild, listCategories) }}
                                                </div>
                                            </div>

                                            <template v-for="(item, k) in listCategoriesSub">
                                                <div
                                                    :key="k + item.category_id + 's'"
                                                    class="mb-1"
                                                    style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px"
                                                >
                                                    <div class="row">
                                                        <label class="col-sm-3">{{ renderNameCategory(item) }}</label>
                                                    </div>

                                                    <div v-if="renderNameCategory(item) == 'WEB'" class="form-group row">
                                                        <label class="col-sm-3">応募フォーム</label>
                                                        <div class="col-sm-6">
                                                            {{ form.application_form }}
                                                        </div>
                                                    </div>

                                                    <div v-if="renderNameCategory(item) == '流通' && form.campaign_type == 81" class="form-group row">
                                                        <label class="col-sm-3">流通数</label>
                                                        <div class="col-sm-6">
                                                            {{ item.extension.number_of_circulation ?? '' }}
                                                        </div>
                                                    </div>

                                                    <div
                                                        v-if="renderNameCategory(item) == 'タイアップ' && form.campaign_type == 81"
                                                        class="form-group row"
                                                    >
                                                        <label class="col-sm-3">企業情報</label>
                                                        <div class="col-sm-6" v-html="textLineBreaks(item.extension.company_information) ?? ''" />
                                                    </div>

                                                    <div v-if="renderNameCategory(item) == 'その他'" class="form-group row">
                                                        <label class="col-sm-3"></label>
                                                        <div class="col-sm-6" v-html="textLineBreaks(item.extension.other_type_data) ?? ''" />
                                                    </div>

                                                    <div
                                                        v-if="
                                                            renderNameCategory(item) == 'WEB' ||
                                                            renderNameCategory(item) == 'ハガキ' ||
                                                            renderNameCategory(item) == 'Twitter' ||
                                                            renderNameCategory(item) == 'Instagram' ||
                                                            renderNameCategory(item) == 'LINE' ||
                                                            renderNameCategory(item) == 'Tiktok' ||
                                                            renderNameCategory(item) == '店頭' ||
                                                            renderNameCategory(item) == 'アプリ'
                                                        "
                                                        class="form-group row"
                                                    >
                                                        <label class="col-sm-3">応募条件①</label>
                                                        <div class="col-sm-6">
                                                            <div style="position: relative">
                                                                {{ renderName(item.application_condition_1, item.listCondition1) }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row" v-if="item.application_condition_1 && item.application_condition_1.id">
                                                        <label class="col-sm-3">応募条件②</label>
                                                        <div class="col-sm-8">
                                                            <div class="d-flex flex-wrap">
                                                                <div>
                                                                    {{
                                                                        handleRenderNameCondition2(
                                                                            item.listCondition2,
                                                                            item.application_condition_2_id
                                                                        )
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <label class="col-sm-3">応募方法</label>
                                                        <div class="col-sm-8">
                                                            <p v-html="textLineBreaks(item?.extension?.application_method) ?? ''"></p>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="form-group row mt-2"
                                                        v-if="item.application_condition_2_id.length > 0 && item.checkSNS >= 0"
                                                    >
                                                        <label class="col-sm-3" for="validationCustom05">公式アカウント名</label>
                                                        <div class="col-sm-8">
                                                            {{ item.extension.offical_name_sns }}
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="form-group row mt-2"
                                                        v-if="item.application_condition_2_id.length > 0 && item.checkSNS >= 0"
                                                    >
                                                        <label class="col-sm-3" for="validationCustom05">公式ID</label>
                                                        <div class="col-sm-8">
                                                            {{ item.extension.offical_id_sns }}
                                                        </div>
                                                    </div>

                                                    <div
                                                        v-if="
                                                            renderNameCategory(item) == 'Twitter' ||
                                                            renderNameCategory(item) == 'Tiktok' ||
                                                            renderNameCategory(item) == 'Instagram'
                                                        "
                                                        class="form-group row"
                                                    >
                                                        <label class="col-sm-3">ハッシュタグ</label>
                                                        <div class="col-sm-6">
                                                            {{ handleRenderHashTag(item.configHashTag.arrayTag) }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>

                                            <div v-if="isShowConditionBuyAndSell">
                                                <div class="form-group row">
                                                    <label class="col-sm-3">購買条件</label>
                                                    <div class="col-sm-6">
                                                        {{ handleRenderNameCommon(listDataMasterConditionPurchase1, form.purchase_conditions) }}
                                                    </div>
                                                </div>

                                                <template v-for="item in form.purchase_conditions">
                                                    <div
                                                        class="form-group row"
                                                        :key="'target' + item"
                                                        style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px"
                                                    >
                                                        <label class="col-sm-3">{{ renderNamePurchase(item) }} 入力 </label>
                                                        <div class="col-sm-6">
                                                            <div>{{ purchase_conditions[item] ?? '' }}</div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>

                                            <div v-if="isShowReceiptExpirationDate">
                                                <div class="form-group row">
                                                    <label class="col-sm-3">対象商品カテゴリ</label>
                                                    <div class="col-sm-9">
                                                        {{ handleRenderNameCommon(listDataMasterCategoryProductTarget, form.target_product_lists) }}
                                                    </div>
                                                </div>

                                                <template v-for="item in form.target_product_lists">
                                                    <div
                                                        class="form-group row"
                                                        :key="'target' + item"
                                                        style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px"
                                                    >
                                                        <label class="col-sm-3">詳細 {{ renderNameTargetProduct(item) }}</label>
                                                        <div class="col-sm-6">
                                                            <p v-html="textLineBreaks(target_product_lists[item]) ?? ''"></p>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>

                                            <div class="form-group row mt-3">
                                                <label class="col-sm-3">告知</label>
                                                <div class="col-sm-9">
                                                    {{ form.inform }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">LP サイト</label>
                                                <div class="col-sm-9">
                                                    <a target="_blank" :href="form.url">{{ form.url }}</a>
                                                </div>
                                            </div>

                                            <div v-if="isShowPublicTime" class="form-group row">
                                                <label class="col-sm-3">公開期間</label>
                                                <div class="col-sm-9">
                                                    <div class="col-sm-0 mb-2">
                                                        {{ formatDateToChinese(form.url_publish_time_from) }}
                                                        {{ form.url_publish_time_to ? '~' : '' }}
                                                        {{ formatDateToChinese(form.url_publish_time_to) }}
                                                    </div>
                                                    <div class="col-sm-0"></div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">当選者数</label>
                                                <div class="col-sm-9">
                                                    {{ form.total_number_of_win_prize }}
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-3">賞品進呈時期(発送時期・送付時期)</label>
                                                <div class="col-sm-9">
                                                    {{ form.prize_shipping_period }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">お問い合わせ事務局名</label>
                                                <div class="col-sm-9">
                                                    <p v-html="textLineBreaks(form.inquiry_office)"></p>
                                                </div>
                                            </div>

                                            <div v-for="item in listContactInformations" :key="item.id + 's'">
                                                <div v-if="form.contact_informations.includes(item.id)" class="form-group row">
                                                    <label class="col-sm-3">{{ item.subText }}</label>
                                                    <div class="col-sm-9">
                                                        <div v-if="item.child && item.child.length > 0">
                                                            <div v-for="(oChild, idoChild) in item.child" :key="idoChild" class="col-sm-9">
                                                                <div v-if="form.contact_informations.includes(oChild.id)">
                                                                    <div class="form-group row">
                                                                        <label class="col-sm-3">{{ oChild.text }}</label>
                                                                        <div>
                                                                            {{ contact_informations[oChild.id] }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else class="d-flex align-items-center">
                                                            <div
                                                                v-if="item.subText == 'TEL入力'"
                                                                :class="
                                                                    form.phone_setting?.disable_edit_field?.field_pre_order_phone_numbers
                                                                        ? 'mr-3'
                                                                        : ''
                                                                "
                                                            >
                                                                {{
                                                                    form.phone_setting?.disable_edit_field?.field_pre_order_phone_numbers
                                                                        ? contact_informations[item.id]
                                                                        : ''
                                                                }}
                                                            </div>
                                                            <div v-else>
                                                                {{ contact_informations[item.id] }}
                                                            </div>
                                                            <button
                                                                v-if="item.subText == 'TEL入力'"
                                                                type="button"
                                                                class="btn btn-sm btn-primary"
                                                                @click="
                                                                    () => {
                                                                        $router.push({
                                                                            path: '/setting-phone',
                                                                            query: {
                                                                                id: $route.query.id
                                                                            }
                                                                        });
                                                                    }
                                                                "
                                                            >
                                                                電話工事
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">検索キーワード</label>
                                                    <div class="col-sm-9">
                                                        <template v-for="(jtem, k) in form.keywords">
                                                            <span :key="'key' + k"> {{ k > 0 ? ', ' : '' }}{{ jtem }} </span>
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">メモ欄</label>
                                                    <div class="col-sm-9">
                                                        <p v-html="textLineBreaks(form.memo)"></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="ChatworkRoom">Chatwork Room</label>
                                                <div class="col-sm-9">
                                                    <p v-html="handleRenderChatRoom(form.chatwork_rooms)" />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3" for="associated_campaign_ID">{{
                                                    $t('pageCampaign.associated_campaign_ID')
                                                }}</label>
                                                <div class="col-sm-9">
                                                    <a target="_blank" :href="`${$route.path}?id=${form.associated_campaign?.id ?? ''}`">
                                                        {{ form.associated_campaign?.id ?? '' }}</a
                                                    >
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <div class="form-group row">
                                                    <label class="col-sm-3" for="ipInformationItem">{{ $t('pageCampaign.ipInformationItem') }}</label>
                                                    <div class="col-sm-9">
                                                        <p>{{ handleGetNameIP(form.information_ips) }}</p>
                                                    </div>
                                                </div>

                                                <div v-for="itemIP in form.information_ips" :key="itemIP.id">
                                                    <div
                                                        class="mb-1"
                                                        style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px"
                                                    >
                                                        <div class="row">
                                                            <label class="col-sm-3">{{ itemIP.value }}</label>
                                                            <div class="col-sm-6">
                                                                <p v-html="textLineBreaks(itemIP?.note?.detail) ?? ''"></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <button
                                                    class="btn-sm btn btn-link mr-2"
                                                    type="button"
                                                    style="cursor: default"
                                                    v-if="form.is_approved == 0 && !checkPermiss('campaign.edit')"
                                                >
                                                    承認待ち
                                                </button>
                                                <button
                                                    class="btn-sm btn btn-link mr-2"
                                                    type="button"
                                                    style="cursor: default"
                                                    v-if="form.is_approved != 0 && form.is_locked != 1"
                                                >
                                                    承認済み
                                                </button>
                                                <button
                                                    class="btn-sm btn btn-primary mr-2"
                                                    type="button"
                                                    @click="approveCampaigns()"
                                                    v-if="form.is_approved == 0 && checkPermiss('campaign.approve')"
                                                >
                                                    承認
                                                </button>
                                                <button
                                                    class="btn-sm btn btn-secondary mr-2"
                                                    type="button"
                                                    @click="unLockCampaigns()"
                                                    v-if="form.is_locked == 1 && checkPermiss('campaign.unlock')"
                                                >
                                                    ロック解除
                                                </button>
                                                <button
                                                    class="btn-sm btn btn-info mr-2"
                                                    type="button"
                                                    @click="copyCampaigns()"
                                                    v-if="form.is_approved != 0 && checkPermiss('campaign.copy')"
                                                >
                                                    複製
                                                </button>
                                                <button
                                                    class="btn-sm btn btn-primary mr-2"
                                                    type="button"
                                                    @click="lockCampaigns()"
                                                    v-if="form.is_locked != 1 && form.is_approved != 0 && checkPermiss('campaign.lock')"
                                                >
                                                    ロック
                                                </button>
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block" style="min-width: max-content"
                                                    >受注範囲＆パートナー＆ツール</span
                                                >
                                            </template>
                                            <div>
                                                <div class="table-responsive" style="height: 500px; overflow-y: auto">
                                                    <table class="table mb-0 table-bordered">
                                                        <thead style="position: sticky; top: 0; background-color: #fff; z-index: 99">
                                                            <tr>
                                                                <th>大項目</th>
                                                                <th>中項目</th>
                                                                <th>小項目</th>
                                                                <th>詳細</th>
                                                                <th>パートナー</th>
                                                                <th>ツール</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="(itemTscope1, kScope1) in tableScopeOrder">
                                                                <template v-if="itemTscope1.data && itemTscope1.data.length">
                                                                    <template v-for="(itemTscope2, kScope2) in itemTscope1.data">
                                                                        <template v-if="itemTscope2.data && itemTscope2.data.length">
                                                                            <template v-for="(itemTscope3, kScope3) in itemTscope2.data">
                                                                                <template v-if="itemTscope3.data && itemTscope3.data.length">
                                                                                    <tr
                                                                                        v-for="(itemTscope4, kScope4) in itemTscope3.data"
                                                                                        :key="`${kScope4}=>${kScope3}=>${kScope2}=>${kScope1}`"
                                                                                    >
                                                                                        <td>
                                                                                            <div style="min-width: 80px">
                                                                                                {{ itemTscope1.value }}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div style="min-width: 100px">
                                                                                                {{ itemTscope2.value }}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div style="min-width: 100px">
                                                                                                {{ itemTscope3.value }}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div style="min-width: 100px">
                                                                                                {{ itemTscope4.value }}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style="width: 320px">
                                                                                            <div style="max-width: 320px">
                                                                                                {{ itemTscope4.partner_corresponding?.name ?? '' }}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style="width: 320px">
                                                                                            <div style="max-width: 320px">
                                                                                                {{ itemTscope4.tool_corresponding?.tool ?? '' }}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <tr :key="`${kScope1}=>${kScope2}=>${kScope3}`">
                                                                                        <td>
                                                                                            <div style="min-width: 80px">
                                                                                                {{ itemTscope1.value }}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div style="min-width: 100px">
                                                                                                {{ itemTscope2.value }}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div style="min-width: 100px">
                                                                                                {{ itemTscope3.value }}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div style="min-width: 100px"></div>
                                                                                        </td>
                                                                                        <td style="width: 320px">
                                                                                            <div style="max-width: 320px">
                                                                                                {{ itemTscope3.partner_corresponding?.name ?? '' }}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style="width: 320px">
                                                                                            <div style="max-width: 320px">
                                                                                                {{ itemTscope3.tool_corresponding?.tool ?? '' }}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>
                                                                            </template>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr :key="`${kScope1}=>${kScope2}`">
                                                                                <td>
                                                                                    <div style="min-width: 80px">
                                                                                        {{ itemTscope1.value }}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style="min-width: 100px">
                                                                                        {{ itemTscope2.value }}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style="min-width: 100px"></div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style="min-width: 100px"></div>
                                                                                </td>
                                                                                <td style="width: 320px">
                                                                                    <div style="max-width: 320px">
                                                                                        {{ itemTscope2.partner_corresponding?.name ?? '' }}
                                                                                    </div>
                                                                                </td>
                                                                                <td style="width: 320px">
                                                                                    <div style="max-width: 320px">
                                                                                        {{ itemTscope2.tool_corresponding?.tool ?? '' }}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <tr :key="`<=${kScope1}`">
                                                                        <td>
                                                                            <div style="min-width: 80px">
                                                                                {{ itemTscope1.value }}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div style="min-width: 100px"></div>
                                                                        </td>
                                                                        <td>
                                                                            <div style="min-width: 100px"></div>
                                                                        </td>
                                                                        <td>
                                                                            <div style="min-width: 100px"></div>
                                                                        </td>
                                                                        <td style="width: 320px">
                                                                            <div style="max-width: 320px">
                                                                                {{ itemTscope1.partner_corresponding?.name ?? '' }}
                                                                            </div>
                                                                        </td>
                                                                        <td style="width: 320px">
                                                                            <div style="max-width: 320px">
                                                                                {{ itemTscope1.tool_corresponding?.tool ?? '' }}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </template>
                                                            <tr></tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-user"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">お客様情報</span>
                                            </template>

                                            <div class="form-group row">
                                                <label class="col-sm-3">エンドクライアント</label>
                                                <div class="col-sm-8">
                                                    <div class="d-flex">
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>エンドクライアント名</th>
                                                                    <th>担当</th>
                                                                    <th>部署</th>
                                                                    <th>メールアドレス</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(item, k) in form.clients" :key="k + 'fc' + item.client_id">
                                                                    <td>{{ item.name }}</td>
                                                                    <td>{{ item.client_pic_name }}</td>
                                                                    <td>{{ item.client_pic_department }}</td>
                                                                    <td>{{ item.client_pic_email }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <button
                                                            type="button"
                                                            class="btn btn-sm btn-success ml-5"
                                                            style="white-space: nowrap; opacity: 0"
                                                        >
                                                            新規登録
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--              Agency-->

                                            <div class="form-group row">
                                                <label class="col-sm-3">発注元</label>
                                                <div class="col-sm-8">
                                                    <div class="d-flex">
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>代理店名</th>
                                                                    <th>担当</th>
                                                                    <th>部署</th>
                                                                    <th>メールアドレス</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(item, k) in form.agencies" :key="k + 'fa' + item.agency_id">
                                                                    <td>{{ item.name }}</td>
                                                                    <td>{{ item.agency_pic_name }}</td>
                                                                    <td>{{ item.agency_pic_department }}</td>
                                                                    <td>{{ item.agency_pic_email }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <button
                                                            type="button"
                                                            class="btn btn-sm btn-success ml-5"
                                                            style="white-space: nowrap; opacity: 0"
                                                        >
                                                            新規登録
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">代理店</label>
                                                <div class="col-sm-8">
                                                    <div>
                                                        {{ form.source_data ? (form.source_data.type == 1 ? 'エンドクライアント' : '代理店') : '' }}
                                                    </div>
                                                    <div>{{ form.source_data ? form.source_data.name : '' }}</div>
                                                </div>
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">事務局担当情報</span>
                                            </template>

                                            <div class="form-group row">
                                                <label class="col-sm-3">営業担当</label>
                                                <div class="col-sm-8">
                                                    {{ saleStaffObject.name }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 pr-0">対応ディレクションチーム</label>
                                                <div class="col-sm-8">
                                                    {{ teamObject.value }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">案件統括</label>
                                                <div class="col-sm-8">
                                                    {{ form.project_management?.name ?? '' }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">担当</label>
                                                <div class="col-sm-8">
                                                    <div class="row">
                                                        <div class="col-sm-8">
                                                            <div class="d-flex align-items-center">
                                                                <span style="width: 60px">メイン</span>
                                                                {{ personInChargeObject.name }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4"></div>
                                                    </div>
                                                    <div class="row mt-2" v-for="(pTem, k) in listItemPersons" :key="'p' + k">
                                                        <div class="col-sm-8">
                                                            <div class="d-flex align-items-center">
                                                                <span style="width: 60px">サブ{{ k + 1 }}</span>
                                                                {{ listItemPersons[k].name }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3 pr-0">エスカレー担当</label>
                                                <div class="col-sm-8">
                                                    {{ customerTeamPic?.name ?? '' }}
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">アルバイト</label>
                                                <div class="col-sm-9">
                                                    {{ handleRenderNameEmployePT(form.part_time_employees) }}
                                                </div>
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">賞品情報</span>
                                            </template>
                                            <div>
                                                <div class="table-responsive" style="height: 450px; overflow-y: auto">
                                                    <table class="table mb-0 table-bordered">
                                                        <thead style="position: sticky; top: 0; background-color: #fff; z-index: 99">
                                                            <tr>
                                                                <th>賞品タイトル <span class="text-danger">*</span></th>
                                                                <th>当選人数</th>
                                                                <th>抽選人数</th>
                                                                <th>賞品名 <span class="text-danger">*</span></th>
                                                                <th>数量</th>
                                                                <th>タイトルに対して数量</th>
                                                                <th>入庫数（顧客から）</th>
                                                                <th>残数</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="(item, k) in arrayObjectPizes">
                                                                <tr
                                                                    v-for="(itemPN, idx) in item.arrayNameAndQuantity"
                                                                    :key="'arrayObjectPizes' + k + idx"
                                                                >
                                                                    <td
                                                                        v-if="idx == 0"
                                                                        :rowspan="item.arrayNameAndQuantity.length"
                                                                        style="vertical-align: middle"
                                                                    >
                                                                        <div style="min-width: 250px">
                                                                            {{ item.title ?? '' }}
                                                                        </div>
                                                                    </td>

                                                                    <td
                                                                        v-if="idx == 0"
                                                                        :rowspan="item.arrayNameAndQuantity.length"
                                                                        style="vertical-align: middle"
                                                                    >
                                                                        <div style="min-width: 100px">
                                                                            {{ item.number_of_win_prize ?? 0 }}
                                                                        </div>
                                                                    </td>

                                                                    <td
                                                                        v-if="idx == 0"
                                                                        :rowspan="item.arrayNameAndQuantity.length"
                                                                        style="vertical-align: middle"
                                                                    >
                                                                        <div style="min-width: 100px">
                                                                            {{ item.number_of_people_drawing_lots ?? 0 }}
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div style="min-width: 250px; flex: 1">
                                                                            {{ itemPN.prize_name ?? '' }}
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div
                                                                            :class="{
                                                                                'mb-3': item.arrayNameAndQuantity.length !== 1
                                                                            }"
                                                                            style="min-width: 100px"
                                                                        >
                                                                            {{ itemPN.quantity ?? 0 }}
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div style="min-width: 150px">
                                                                            {{ itemPN.quantity_title ?? 0 }}
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div style="min-width: 150px">
                                                                            {{ itemPN.quantity_received ?? 0 }}
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div style="min-width: 150px">
                                                                            {{ itemPN.quantity_remaining ?? 0 }}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">実施結果登録</span>
                                            </template>
                                            <div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">GA有無（LP）</label>
                                                    <div class="col-sm-6">
                                                        {{ form.give_away == 0 ? 'NO' : 'YES' }}
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-sm-3">GAからの取得情報<br />（LPからの）⇒</label>
                                                    <div class="col-sm-9"></div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">流入先</label>
                                                    <div class="col-sm-4">
                                                        {{ form.give_away_information?.inflow_destination ?? '' }}
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">流入率</label>
                                                    <div class="col-sm-4">
                                                        {{ form.give_away_information?.inflow_rate ?? '' }}
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">離脱率</label>
                                                    <div class="col-sm-4">
                                                        {{ form.give_away_information?.attrition_rate ?? '' }}
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">ATELU（SNS収集ツール）<br />レポート格納</label>
                                                    <div class="col-sm-9">
                                                        <div
                                                            class="row mb-1"
                                                            v-for="(item, k) in form.campaign_sns_collections_tools"
                                                            :key="k + 'sns' + item.id"
                                                        >
                                                            <div class="col-sm-6 align-items-center">
                                                                <a target="_blank" :href="item.path">{{ item.origin_name }}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">キャッシュバックの応募者不備件数/当選者に対しての％</label>
                                                    <div class="col-sm-4">
                                                        {{ form.defects_in_cash_back_applicants_rate }}
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">SNS当選者フォーム登録なし件数/当選者に対しての％</label>
                                                    <div class="col-sm-4">
                                                        {{ form.sns_winners_without_registration_rate }}
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">LINE友達増減数</label>
                                                    <div class="col-sm-4">
                                                        {{ form.line_friends_growth }}
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">広告媒体</label>
                                                    <div class="col-sm-4">
                                                        {{ form.advertising_media }}
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">広告費</label>
                                                    <div class="col-sm-4">
                                                        {{ form.advertising_cost }}
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3">広告出稿による結果</label>
                                                    <div class="col-sm-4">
                                                        {{ form.advertising_result }}
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-sm-3"
                                                        >SNSのフォロワー増加数<br />
                                                        <small>※SNSの時に分かれば入力</small></label
                                                    >
                                                    <div class="col-sm-9">
                                                        {{ form.number_of_sns_followers }}
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-sm-3">応募数</label>
                                                    <div class="col-sm-8">
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">総応募数</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_applications.total }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">ハガキ・郵送</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_applications.postcard_mail }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">web</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_applications.web }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">パルなび</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_applications.palnavi }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">SNS</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_applications.sns }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">Twitter</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_applications.twitter }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">Instagram</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_applications.instagram }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">LINE</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_applications.line }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">無効</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_applications.invalid }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-sm-3">問い合わせ件数</label>
                                                    <div class="col-sm-8">
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">総問い合わせ数</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_inquiries.total }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">TEL</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_inquiries.tel }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">メール</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_inquiries.email }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">それ以外</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_inquiries.number_of_cases }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">イレギュラー</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_inquiries.irregular }}
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label class="col-sm-3">イレギュラー内容</label>
                                                            <div class="col-sm-5">
                                                                {{ form.number_of_inquiries.irregular_content }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">編集履歴</span>
                                            </template>
                                            <div class="col-sm-6">
                                                <div class="table-responsive">
                                                    <table class="table mb-0 table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>権限</th>
                                                                <th>日時</th>
                                                                <th>名称</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="item in listHistory">
                                                                <tr :key="'c' + item.id">
                                                                    <td>{{ item.user && item.user.role ? $t(item.user.role.name) : '' }}</td>

                                                                    <td>{{ getCurrentDateTime(item.created_at) }}</td>
                                                                    <td>{{ item.user ? item.user.name : '' }}</td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </b-tab>
                                    </b-tabs>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center align-items-center">
                    <div style="font-size: 20px; cursor: pointer">
                        <i v-if="isBookmarkCampaign" class="ri-bookmark-fill" style="color: #157efb" @click="handleUnsetBookmarkCampaign"></i>
                        <i v-else class="ri-bookmark-line" @click="handleSetBookmarkCampaign"></i>
                    </div>
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: '/campaign/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="form.is_approved != 0 && form.is_locked != 1 && checkPermiss('campaign.edit')"
                            type="button"
                            class="btn btn-primary"
                            @click="goToEdit('/campaign/edit', $route.query.id)"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermiss('campaign.delete')"
                            type="button"
                            class="btn btn-danger ml-3"
                            @click="deleteObject({ id: $route.query.id })"
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>

            <ModalCommon id="ModalOrder" :config="configModalCommon" @close="handleCloseScopeOrder()">
                <form class="needs-validation" autocomplete="off">
                    <div class="form-group row">
                        <label class="col-sm-2 mw--form" for="validationCustom05">受注範囲</label>
                        <div class="col-sm-9">
                            <div class="d-flex flex-wrap">
                                <div
                                    class="custom-control custom-checkbox mr-4 py-1"
                                    style="min-width: 100px"
                                    v-for="orderLv1 in listDataMasterOrderScope"
                                    :key="orderLv1.id"
                                >
                                    <InputCheckBox
                                        :model.sync="listChecked.msOrderLv1"
                                        :config="{
                                            id: `check_app${orderLv1.id}`,
                                            value: orderLv1.id,
                                            label: orderLv1.value
                                        }"
                                        disabled
                                        @change="handleChangeOrderLv1(orderLv1)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="listChecked.msOrderLv1.length">
                        <div
                            v-for="itemFilterLv1 in listOrderFilterLv1"
                            :key="`${itemFilterLv1.id}-filter1`"
                            class="py-3 mt-3"
                            style="box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px; border-radius: 10px"
                        >
                            <div>
                                <div class="ml-1 my-4">
                                    <label class="custom-control px-3">{{ itemFilterLv1.value }}</label>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-1"></div>
                                    <div class="col-sm-11 row">
                                        <div v-for="(dataFilterLv1, idxF1) in itemFilterLv1.data" :key="`${idxF1}-medium_item_filter1`">
                                            <InputCheckBox
                                                class="mr-4 py-1"
                                                style="min-width: 100px"
                                                :model.sync="listChecked.msOrderLv2"
                                                :config="{
                                                    id: `check_app${dataFilterLv1.id}`,
                                                    value: dataFilterLv1.id,
                                                    label: dataFilterLv1.value
                                                }"
                                                disabled
                                                @change="handleChangeOrderLv2(dataFilterLv1)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-for="itemFilterLv2 in listOrderFilterLv2" :key="`${itemFilterLv2.id}-filter2`">
                                <div v-if="handleVisibleLv2(itemFilterLv1, itemFilterLv2)">
                                    <div v-if="itemFilterLv2.data.length > 0" style="border-top: 1px solid rgb(181 180 180 / 50%)">
                                        <div class="ml-4 my-4">
                                            <label class="custom-control"
                                                >{{ itemFilterLv1.value }} <span class="font-weight-bold px-1">/</span> {{ itemFilterLv2.value }}
                                            </label>
                                        </div>

                                        <div class="form-group row">
                                            <div class="col-sm-1"></div>
                                            <div class="col-sm-11 row">
                                                <div v-for="(dataFilterLv2, idxF2) in itemFilterLv2.data" :key="`${idxF2}-medium_item_filter2`">
                                                    <div v-if="handleShowOptionOneFilterLv2(itemFilterLv2)">
                                                        <InputRadio
                                                            class="mr-4"
                                                            :model.sync="listRadio.msOrderLv2[itemFilterLv2.id]"
                                                            :config="{
                                                                id: `radio-${dataFilterLv2.id}`,
                                                                value: dataFilterLv2.id,
                                                                label: dataFilterLv2.value,
                                                                name: `msOrderLv2-${itemFilterLv2.id}`
                                                            }"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div v-else>
                                                        <InputCheckBox
                                                            class="mr-4 py-1"
                                                            style="min-width: 100px"
                                                            :model.sync="listChecked.msOrderLv3"
                                                            :config="{
                                                                id: `check_app${dataFilterLv2.id}`,
                                                                value: dataFilterLv2.id,
                                                                label: dataFilterLv2.value
                                                            }"
                                                            disabled
                                                            @change="handleChangeOrderLv3(dataFilterLv2)"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-for="itemFilterLv3 in listOrderFilterLv3" :key="`${itemFilterLv3.id}-filter3`">
                                    <div v-if="handleVisibleLv3(itemFilterLv1, itemFilterLv2, itemFilterLv3)">
                                        <div v-if="itemFilterLv3.data.length > 0" style="border-top: 1px solid rgb(181 180 180 / 50%)">
                                            <div class="ml-4 my-4">
                                                <label class="custom-control"
                                                    >{{ itemFilterLv1.value }} <span class="font-weight-bold px-1">/</span> {{ itemFilterLv2.value }}
                                                    <span class="font-weight-bold px-1">/</span> {{ itemFilterLv3.value }}</label
                                                >
                                            </div>

                                            <div class="form-group row">
                                                <div class="col-sm-1"></div>
                                                <div class="col-sm-11 row">
                                                    <div v-for="(dataFilterLv3, idxF3) in itemFilterLv3.data" :key="`${idxF3}-medium_item_filter3`">
                                                        <div v-if="handleShowOptionOneFilterLv3(itemFilterLv3)">
                                                            <InputRadio
                                                                class="mr-4"
                                                                :model.sync="listRadio.msOrderLv4[itemFilterLv3.id]"
                                                                :config="{
                                                                    id: `radio-filter-${dataFilterLv3.id}`,
                                                                    value: dataFilterLv3.id,
                                                                    label: dataFilterLv3.value,
                                                                    name: `radio-filter-lv4-${itemFilterLv3.id}`
                                                                }"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div v-else>
                                                            <InputCheckBox
                                                                class="mr-4 py-1"
                                                                style="min-width: 100px"
                                                                :model.sync="listChecked.msOrderLv4"
                                                                :config="{
                                                                    id: `check_app${dataFilterLv3.id}`,
                                                                    value: dataFilterLv3.id,
                                                                    label: dataFilterLv3.value
                                                                }"
                                                                disabled
                                                                @change="handleChangeOrderLv4(dataFilterLv3)"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ModalCommon>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>

<style lang="scss">
.modal-custom-view-cp {
    .modal-content {
        height: 100vh;
    }
}
</style>
